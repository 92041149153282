import * as $ from 'jquery';

if($('#account_email').length > 0) {
  $('#account_email').focus(function() {
    $('#account_email').css('width', '300px');
  });

  $('#account_email').focusout(function() {
    let val = $('#account_email').val().toString().trim();

    if (val.length == 0) {
      $('#account_email').css('width', 'initial');
    }
  });
}