import Cookie from "js-cookie";
import * as $ from "jquery";
import * as Sentry from "@sentry/browser";
declare var chaport: any;
declare var Cohere: any;

class ExternalSdks {
  private id: string;
  private displayName: string;
  private email: string;

  constructor() {
    this.id = Cookie.get("shareable_id");
    this.displayName = Cookie.get("display_name");
    this.email = Cookie.get("email");
  }

  update(): void {
    if (this.heapAvailable()) {
      this.updateHeap();
    }

    if (this.fullStoryAvailable()) {
      this.updateFullStory();
    }

    if (this.cohereAvailable()) {
      this.updateCohere();
    }
  }

  updateChaport(name) {
    if (typeof chaport !== 'undefined') {
       if (chaport.hasOwnProperty('setVisitorData')) {
         chaport.startSession();
         chaport.setVisitorData({name: name});
       } else {
         chaport.q('setVisitorData', {name: name});
       }
    }
  }

  updateSentry(dsn_key) {
    Sentry.init({ dsn: dsn_key });
    Sentry.setUser({
      id: this.id,
      username: this.displayName
    });
  }

  private heapAvailable() {
    return typeof heap !== "undefined" && this.id;
  }

  private updateHeap() {
    heap.identify(this.id);
    heap.addUserProperties({
      display_name: this.displayName
    });
  }

  private fullStoryAvailable() {
    return typeof FS !== "undefined" && this.id;
  }

  private cohereAvailable() {
    return typeof Cohere !== "undefined" && this.id;
  }

  private updateFullStory() {
    FS.identify(this.id, {
      displayName: this.displayName
    });
  }

  private updateCohere() {
    Cohere.identify(this.id, {
      displayName: this.displayName,
      email: this.email
    });
  }
}

window["ExternalSdks"] = new ExternalSdks();
document.addEventListener("turbolinks:load", function() {
  new ExternalSdks().update();

  let targetNode = document.body;
  let config = { childList: true };
  let callback = function() {
    if (document.body.querySelector("#fc_frame")) {
      document.documentElement.appendChild(document.getElementById("fc_frame"));
      observer.disconnect();
    }
  };

  let observer = new MutationObserver(callback);
  if (!document.querySelector("html > #fc_frame")) {
    observer.observe(targetNode, config);
  }
});
