import maskInput from "vanilla-text-mask/dist/vanillaTextMask";

document.addEventListener("turbolinks:load", function() {
  let node = document.getElementById("lead-phone");
  if (node) {
    let phoneMask = [
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
    maskInput({
      inputElement: node,
      mask: phoneMask
    });
  }
});
